import React from 'react';

import { PAGES } from '../utils/utils';
import Competences from '../components/fr/competences';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CompetencesPage = () => (
  <Layout page={PAGES.COMPETENCES}>
    <SEO title="Compétences" />
    <Competences />
  </Layout>
);

export default CompetencesPage;
