import { Link } from 'gatsby';
import React, { useEffect } from 'react';

import { setupHaccordion } from '../../scripts/competences-haccordion';
import { getYearsSinceOfficeBirthFactorFive } from '../../utils/utils';

const Competences = () => {
  useEffect(() => {
    setupHaccordion();
  });

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Compétences</h1>
      </div>

      <div id="img-wrap">
        <img
          src="/img/lemire-competences-large.jpg"
          alt="Compétences"
          title="Compétences"
        />
      </div>

      <div id="infoComp">
        <p id="infoCompP">
          <span className="accent2">Lemire Lemire avocats s.e.n.c.r.l.</span>{' '}
          est fier d'offrir à ses clients une gamme complète de services dans
          plusieurs domaines du droit. Notre approche responsable et
          individualisée nous permet de trouver la meilleure solution pour vous
          dans le respect de vos droits et vos besoins.
        </p>

        <div id="hc1" className="haccordion">
          <ul>
            {/* Droit de la famille */}
            <li id="comp1" className="activeText">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img1">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Séparation-Divorce</h2>
                          <p>
                            Une séparation ou un divorce constitue souvent un
                            bouleversement majeur dans la vie des personnes
                            concernées et apporte son lot de questions. &nbsp;
                            <Link to="/competences/separation-divorce">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Garde d'enfants */}
            <li id="comp3">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img2">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Garde d'enfants</h2>
                          <p>
                            Résoudre les divergences relatives aux droits de
                            garde des enfants et aux droits de visites des
                            parents. <br />
                            <Link to="/competences/garde-enfant">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Médiation */}
            <li id="comp2">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img3">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Médiation</h2>
                          <p>
                            La médiation familiale peut vous aider à négocier
                            une entente à l'amiable, dans l'intérêt de tous les
                            membres de la famille. &nbsp;
                            <Link to="/competences/mediation">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>

        <br />

        <div id="hc2" className="haccordion">
          <ul>
            {/* Responsabilité */}
            <li id="comp4">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img4">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Responsabilité</h2>
                          <p>
                            Nous déployons tous nos efforts à la recherche du
                            meilleur résultat pour l'indemnisation de vos
                            préjudices. <br />
                            <Link to="/competences/responsabilite">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Construction */}
            <li id="comp5">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img5">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Construction</h2>
                          <p>
                            Nous vous aiderons notamment en matière de vices
                            cachés, d'hypothèque légale de la construction et de
                            cautionnement. <br />
                            <Link to="/competences/construction">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Assurances */}
            <li id="comp6">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img6">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Assurances</h2>
                          <p>
                            Nous sommes qualifiés pour résoudre les conflits
                            liés aux réclamations d'assurances. <br />
                            <Link to="/competences/assurances">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Immobilier */}
            <li id="comp7">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img7">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Immobilier</h2>
                          <p>
                            Nos avocats travailleront avec vous et vous
                            informeront notamment sur les troubles de voisinage,
                            les servitudes, la prescription acquisitive et la
                            copropriété. &nbsp;
                            <Link to="/competences/immobilier">
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>

        {/* ********************************************************************************* */}
        {/* Le contenu pour le responsive design se trouve en bas */}

        <div className="competencesDiv">
          <ul>
            {/* Droit de la famille */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img1b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Séparation-Divorce</h2>
                          <p>
                            Une <span className="accent">séparation</span> ou un{' '}
                            <span className="accent">divorce</span> constitue
                            souvent un bouleversement majeur et apporte son lot
                            de questions : qu'adviendra-t-il de la{' '}
                            <span className="accent2">garde des enfants</span>,
                            qui subviendra à leurs besoins et à concurrence de
                            quel montant, que faire de la{' '}
                            <span className="accent2">résidence familiale</span>
                            , qui remboursera les dettes familiales, puis-je
                            exiger une{' '}
                            <span className="accent2">pension alimentaire</span>{' '}
                            de mon conjoint ou devrai-je en verser une?
                          </p>
                          <p>
                            Nos avocats expérimentés, dont l'un compte plus de{' '}
                            <span className="accent">
                              {getYearsSinceOfficeBirthFactorFive()} ans
                              d'expérience
                            </span>{' '}
                            dans le domaine, verront à vous guider tout au long
                            du processus et travailleront efficacement avec vous
                            à la recherche des meilleures solutions pour vous
                            dans un climat d'écoute et de professionnalisme.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Garde d'enfants */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img2b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Garde d'enfants</h2>
                          <p>
                            Résoudre les divergences relatives aux droits de{' '}
                            <span className="accent">garde des enfants</span> et
                            aux{' '}
                            <span className="accent">droits de visites</span>{' '}
                            des parents a un impact important sur la famille et
                            son fonctionnement dans le futur. Nos avocats font
                            preuve de <span className="accent2">patience</span>,{' '}
                            <span className="accent2">diplomatie</span> et{' '}
                            <span className="accent2">détermination</span> dans
                            la résolution de ces problèmes en ayant toujours
                            comme objectif premier le meilleur intérêt des
                            enfants.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Médiation */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img3b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Médiation</h2>
                          <p>
                            La médiation familiale peut vous aider à négocier
                            une{' '}
                            <span className="accent">entente à l'amiable</span>,
                            dans l'intérêt de tous les membres de la famille et
                            à réduire vos frais juridiques par le biais d'un
                            nombre déterminé de séances subventionnées par le
                            Gouvernement du Québec.{' '}
                          </p>
                          <p>
                            Le recours à un{' '}
                            <span className="accent2">
                              médiateur indépendant et qualifié
                            </span>{' '}
                            permet aux parties de négocier à force égale la mise
                            en place d'une entente post-rupture respectant les
                            besoins distincts de chacune d'elles.{' '}
                          </p>
                          <p>
                            Les parties déjà séparées ou divorcées qui veulent
                            apporter un changement à leur entente ou jugement
                            peuvent également y arriver dans le cadre de{' '}
                            <span className="accent2">négociations</span> en
                            médiation familiale.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Responsabilité */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img4b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Responsabilité</h2>
                          <p>
                            Nos avocats gèrent une grande variété de
                            réclamations pour{' '}
                            <span className="accent">préjudice corporel</span>.
                            Les{' '}
                            <span className="accent2">lésions corporelles</span>{' '}
                            ont souvent un impact à long terme sur la qualité de
                            vie de nos clients et c'est pourquoi nous déployons
                            tous nos efforts à la recherche du meilleur résultat
                            pour l'
                            <span className="accent2">indemnisation</span> de
                            leur préjudice.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Construction */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img5b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Construction</h2>
                          <p>
                            Pendant plus de{' '}
                            <span className="accent">
                              {getYearsSinceOfficeBirthFactorFive()} ans
                            </span>
                            , notre firme a représenté les intervenants dans le
                            domaine de la construction :{' '}
                            <span className="accent2">
                              propriétaire, donneurs d'ouvrage, entrepreneurs,
                              sous-entrepreneurs
                            </span>
                            , etc. Notre expérience touche tout type de projet
                            de construction résidentiel et commercial. Nous vous
                            aiderons notamment en matière de{' '}
                            <span className="accent">vices cachés</span>, d'
                            <span className="accent">
                              hypothèque légale de la construction
                            </span>{' '}
                            et de <span className="accent">cautionnement</span>.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Assurances */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img6b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Assurances</h2>
                          <p>
                            Nous sommes qualifiés pour résoudre les conflits
                            reliés aux{' '}
                            <span className="accent">
                              réclamations d'assurances
                            </span>{' '}
                            qui incluent tout type de réclamations notamment{' '}
                            <span className="accent">
                              automobile, habitation, invalidité
                            </span>{' '}
                            et <span className="accent">vie.</span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Immobilier */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img7b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Immobilier</h2>
                          <p>
                            Il se peut que des mésententes surviennent après
                            l'achat d'une nouvelle résidence.
                          </p>
                          <p>
                            Il peut s'agir de demandes d'annulation de vente,
                            diminution du prix de vente, troubles de voisinage,
                            problèmes de limites de propriété ou de mitoyenneté,
                            etc.
                          </p>
                          <p>
                            Dans tous ces cas, nos services vous seront utiles
                            pour bien défendre vos droits, et ce, de la
                            meilleure façon.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
        {/* Close the section for responsive design */}
      </div>
    </>
  );
};

Competences.propTypes = {};

export default Competences;
