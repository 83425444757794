/* Horizontal Accordion script
 * Created: Oct 27th, 2009. This notice must stay intact for usage
 * Author: Dynamic Drive at http://www.dynamicdrive.com/
 * Visit http://www.dynamicdrive.com/ for full source code
 */

//** Jan 04, 12'- Script updated to work properly in popular mobile devices such as iPad/iPhone and Android tablets.

const defaultCompText =
  '<h2>Divorce-s&eacute;paration</h2><p>Une <span class="accent">s&eacute;paration</span> ou un <span class="accent">divorce</span> constitue souvent un bouleversement majeur et apporte son lot de questions : qu’adviendra-t-il de la <span class="accent2">garde des enfants</span>, qui subviendra &agrave; leurs besoins et &agrave; concurrence de quel montant, que faire de la <span class="accent2">r&eacute;sidence familiale</span>, qui remboursera les dettes familiales, puis-je exiger une <span class="accent2">pension alimentaire</span> de mon conjoint ou devrai-je verser une pension alimentaire?</p><p>Nos avocats exp&eacute;riment&eacute;s, dont l’un compte plus de <span class="accent">35 ans d’exp&eacute;rience</span> dans le domaine, verront &agrave; vous guider tout au long du processus et travailleront efficacement avec vous &agrave; la recherche des meilleures solutions pour vous dans un climat d’&eacute;coute et de professionnalisme.</p>';

// Hack: Append CSS the very first time only since the haccordion is only present in "competences" and "expertise" pages and all attributes are shared.
// This flag object allows to not reappend CSS tags inside the `haccordion.setup` function when rerendering any of these pages.
const isCssAppended = {};

export function setupHaccordion() {
  const haccordion = {
    //customize loading message if accordion markup is fetched via Ajax:
    ajaxloadingmsg:
      '<div style="margin: 1em; font-weight: bold"><img src="ajaxloadr.gif" style="vertical-align: middle" /></div>',

    ismobile:
      navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !=
      null, //boolean check for popular mobile browsers
    accordioninfo: {}, //class that holds config information of each haccordion instance

    expandli: function (accordionid, targetli) {
      $('.activeText').removeClass('activeText');
      var config = haccordion.accordioninfo[accordionid];
      var $targetli =
        typeof targetli == 'number'
          ? config.$targetlis.eq(targetli)
          : typeof targetli == 'string'
          ? jQuery('#' + targetli)
          : jQuery(targetli);
      if (typeof config.$lastexpanded != 'undefined')
        //targetli may be an index, ID string, or DOM reference to LI
        config.$lastexpanded
          .stop()
          .animate({ width: config.paneldimensions.peekw }, config.speed); //contract last opened content
      config.$lastexpanded.removeClass();
      $targetli
        .stop()
        .animate({ width: $targetli.data('hpaneloffsetw') }, config.speed); //expand current content
      $targetli.addClass('active'); // ajoute la classe active pour changer l'image de fond
      $targetli.addClass('activeText');

      var activeid = $('.activeText').attr('id');
      switch (activeid) {
        case 'comp1':
          $('#dynamicWrap').html(defaultCompText);
          break;
        case 'comp2':
          $('#dynamicWrap').html(
            '<h2>M&eacute;diation</h2><p>La m&eacute;diation familiale peut vous aider &agrave; n&eacute;gocier une <span class="accent">entente &agrave; l’amiable</span>, dans l’int&eacute;r&ecirc;t de tous les membres de la famille et &agrave; r&eacute;duire vos frais juridiques par le biais d’un nombre d&eacute;termin&eacute; de s&eacute;ances subventionn&eacute;es par le Gouvernement du Qu&eacute;bec. </p><p>Le recours &agrave; un <span class="accent2">m&eacute;diateur ind&eacute;pendant et qualifi&eacute;</span> permet aux parties de n&eacute;gocier &agrave; force &eacute;gale la mise en place d’une entente post-rupture respectant les besoins distincts de chacune d’elles. </p><p>Les parties d&eacute;j&agrave; s&eacute;par&eacute;es ou divorc&eacute;es qui veulent apporter un changement &agrave; leur entente ou jugement peuvent &eacute;galement y arriver dans le cadre de <span class="accent2">n&eacute;gociations</span> en m&eacute;diation familiale.</p>',
          );
          break;
        case 'comp3':
          $('#dynamicWrap').html(
            '<h2>Garde d\'enfants</h2><p>R&eacute;soudre les divergences relatives aux droits de <span class="accent">garde des enfants</span> et aux <span class="accent">droits de visites</span> des parents a un impact important sur la famille et son fonctionnement dans le futur. Nos avocats font preuve de <span class="accent2">patience</span>, <span class="accent2">diplomatie</span> et <span class="accent2">d&eacute;termination</span> dans la r&eacute;solution de ces probl&egrave;mes en ayant toujours comme objectif premier le meilleur int&eacute;r&ecirc;t des enfants.</p>',
          );
          break;
        case 'comp4':
          $('#dynamicWrap').html(
            '<h2>Responsabilit&eacute;</h2><p>Nos avocats g&egrave;rent une grande vari&eacute;t&eacute; de r&eacute;clamations pour <span class="accent">pr&eacute;judice corporel</span>. Les <span class="accent2">l&eacute;sions corporelles</span> ont souvent un impact &agrave; long terme sur la qualit&eacute; de vie de nos clients et c’est pourquoi nous d&eacute;ployons tous nos efforts &agrave; la recherche du meilleur r&eacute;sultat pour l’<span class="accent2">indemnisation</span> de leur pr&eacute;judice.</p>',
          );
          break;
        case 'comp5':
          $('#dynamicWrap').html(
            '<h2>Construction</h2><p>Pendant plus de <span class="accent">35 ans</span>, notre firme a repr&eacute;sent&eacute; les intervenants dans le domaine de la construction : <span class="accent2">propri&eacute;taire, donneurs d\'ouvrage, entrepreneurs, sous-entrepreneurs</span>, etc. Notre exp&eacute;rience touche tout type de projet de construction r&eacute;sidentiel et commercial. Nous vous aiderons notamment en mati&egrave;re de <span class="accent">vices cach&eacute;s</span>, d’<span class="accent">hypoth&egrave;que l&eacute;gale de la construction</span> et de <span class="accent">cautionnement</span>.</p>',
          );
          break;
        case 'comp6':
          $('#dynamicWrap').html(
            '<h2>Assurances</h2><p>Nous sommes qualifi&eacute;s pour r&eacute;soudre les conflits reli&eacute;s aux <span class="accent">r&eacute;clamations d\'assurances</span> qui incluent tout type de r&eacute;clamations notamment <span class="accent">automobile, habitation, invalidit&eacute;</span> et <span class="accent">vie.</span></p>',
          );
          break;
        case 'comp7':
          $('#dynamicWrap').html(
            '<h2>Immobilier</h2><p>Il se peut que des m&eacute;sententes surviennent apr&egrave;s l&rsquo;achat d&rsquo;une nouvelle r&eacute;sidence.</p><p>Il peut s&rsquo;agir de demandes d&rsquo;annulation de vente, diminution du prix de vente, troubles de voisinage, probl&egrave;mes de limites de propri&eacute;t&eacute; ou de mitoyennet&eacute;, etc.</p><p>Dans tous ces cas, nos services vous seront utiles pour bien d&eacute;fendre vos droits, et ce, de la meilleure fa&ccedil;on.</p>',
          );
          break;
        default:
          alert(activeid);
          break;
      }

      config.$lastexpanded = $targetli;
    },

    urlparamselect: function (accordionid) {
      var result = window.location.search.match(
        new RegExp(accordionid + '=(\\d+)', 'i'),
      ); //check for "?accordionid=index" in URL
      if (result != null) result = parseInt(RegExp.$1) + ''; //return value as string so 0 doesn't test for false
      return result; //returns null or index, where index is the desired selected hcontent index
    },

    getCookie: function (Name) {
      var re = new RegExp(Name + '=[^;]+', 'i'); //construct RE to search for target name/value pair
      if (document.cookie.match(re))
        //if cookie found
        return document.cookie.match(re)[0].split('=')[1]; //return its value
      return null;
    },

    setCookie: function (name, value) {
      document.cookie = name + '=' + value + '; path=/';
    },

    loadexternal: function ($, config) {
      //function to fetch external page containing the entire accordion content markup
      var $hcontainer = $('#' + config.ajaxsource.container).html(
        this.ajaxloadingmsg,
      );
      $.ajax({
        url: config.ajaxsource.path, //path to external content
        async: true,
        error: function (ajaxrequest) {
          $hcontainer.html(
            'Error fetching content.<br />Server Response: ' +
              ajaxrequest.responseText,
          );
        },
        success: function (content) {
          $hcontainer.html(content);
          haccordion.init($, config);
        },
      });
    },

    init: function ($, config) {
      haccordion.accordioninfo[config.accordionid] = config; //cache config info for this accordion
      var $targetlis = $('#' + config.accordionid).find('ul:eq(0) > li'); //find top level LIs
      config.$targetlis = $targetlis;
      config.selectedli = config.selectedli || []; //set default selectedli option
      config.speed = config.speed || 'normal'; //set default speed
      $targetlis.each(function (i) {
        var $target = $(this).data('pos', i); //give each li an index #
        $target.data(
          'hpaneloffsetw',
          $target.find('.hpanel:eq(0)').outerWidth(),
        ); //get offset width of each .hpanel DIV (config.dimensions.fullw + any DIV padding)
        $target[haccordion.ismobile ? 'click' : 'click'](function () {
          haccordion.expandli(config.accordionid, this);
          config.$lastexpanded = $(this);
        });
        if (config.collapsecurrent) {
          //if previous content should be contracted when expanding current
          $target.mouseleave(function () {
            $(this)
              .stop()
              .animate({ width: config.paneldimensions.peekw }, config.speed); //contract previous content
          });
        }
      }); //end $targetlis.each
      var selectedli =
        haccordion.urlparamselect(config.accordionid) ||
        (config.selectedli[1] && haccordion.getCookie(config.accordionid)
          ? parseInt(haccordion.getCookie(config.accordionid))
          : config.selectedli[0]);
      selectedli = parseInt(selectedli);
      if (selectedli >= 0 && selectedli < config.$targetlis.length) {
        //if selectedli index is within range
        config.$lastexpanded = $targetlis.eq(selectedli);
        config.$lastexpanded.addClass('active');
        config.$lastexpanded.css(
          'width',
          config.$lastexpanded.data('hpaneloffsetw'),
        ); //expand selected li
      }
      $(window).bind('unload', function () {
        //clean up and persist on page unload
        haccordion.uninit($, config);
      }); //end window.onunload

      $('#dynamicWrap').html(defaultCompText);
    },

    uninit: function ($, config) {
      var $targetlis = config.$targetlis;
      var expandedliindex = -1; //index of expanded content to remember (-1 indicates non)
      $targetlis.each(function () {
        var $target = $(this);
        $target.unbind();
        if ($target.width() == $target.data('hpaneloffsetw'))
          expandedliindex = $target.data('pos');
      });
      if (config.selectedli[1] == true)
        //enable persistence?
        haccordion.setCookie(config.accordionid, expandedliindex);
    },

    setup: function (config) {
      if (!isCssAppended[config.accordionid]) {
        //Use JS to write out CSS that sets up initial dimensions of each LI, for JS enabled browsers only
        const css = `
    #${config.accordionid} li{width:${config.paneldimensions.peekw};height:${config.paneldimensions.h};}
    #${config.accordionid} li .hpanel{width:${config.paneldimensions.fullw};height:${config.paneldimensions.h}}`;
        const headElement =
          document.head || document.getElementsByTagName('head')[0];
        const styleElement = document.createElement('style');

        headElement.appendChild(styleElement);
        styleElement.type = 'text/css';

        if (styleElement.styleSheet) {
          // This is required for IE8 and below.
          styleElement.styleSheet.cssText = css;
        } else {
          styleElement.appendChild(document.createTextNode(css));
        }

        isCssAppended[config.accordionid] = true;
      }

      if (config.ajaxsource) {
        //if config.ajaxsource option defined
        haccordion.loadexternal($, config);
      } else {
        haccordion.init($, config);
      }
    },
  };

  haccordion.setup({
    accordionid: 'hc1', //main accordion div id
    paneldimensions: { peekw: '130px', fullw: '400px', h: '163px' },
    selectedli: [0, false], //[selectedli_index, persiststate_bool]
    collapsecurrent: false,
  });

  haccordion.setup({
    accordionid: 'hc2', //main accordion div id
    paneldimensions: { peekw: '130px', fullw: '400px', h: '163px' },
    selectedli: [0, false], //[selectedli_index, persiststate_bool]
    collapsecurrent: false,
  });
}
